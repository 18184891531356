import { Helmet } from 'react-helmet-async';
import styles from './index.module.scss';
import HeaderView from '../../components/Header/HeaderView';
import Footer from '../../components/Footer/Footer';

export default function PrivacyPolicy() {
  return (
    <main>
      <Helmet>
        <title>CatLogic | Политика конфиденциальности и обработки персональных данных </title>
        <link rel='canonical' href='https://www.catlogic.tech/privacy-policy' />
      </Helmet>
      <HeaderView />
      <div className={styles.content}>
        <h1>Политика конфиденциальности и обработки персональных данных </h1>
        <div className={styles.container}>
          <h2>1. Общие положения </h2>
          <p>
            Настоящая политика конфиденциальности определяет порядок обработки и защиты персональных
            данных пользователей, предоставляемых ими при использовании Stafflow.by, Catlogic.tech
            (далее - Продукт).
          </p>

          <h2>2. Термины и определения </h2>
          <p>
            Персональные данные - любая информация, относящаяся к идентифицированному физическому
            лицу или физическому лицу, которое может быть идентифицировано. Обработка персональных
            данных - любое действие или совокупность действий, совершаемые с персональными данными,
            включая сбор, систематизацию, хранение, изменение, использование, обезличивание,
            блокирование, распространение, предоставление, удаление персональных данных. Оператор -
            лицо, самостоятельно или совместно с иными лицами организующее и (или) осуществляющее
            обработку персональных данных.:
          </p>

          <h2>3. Принципы обработки персональных данных </h2>
          <ol>
            <li>
              <strong>Законность и добросовестность</strong>: Обработка персональных данных
              осуществляется на законной основе и соразмерна заявленным целям.
            </li>
            <li>
              <strong>Конфиденциальность</strong>: Все персональные данные рассматриваются как
              конфиденциальная информация и защищены в соответствии с законодательством Республики
              Беларусь.
            </li>
            <li>
              <strong>Целевая направленность</strong>: Персональные данные собираются и
              обрабатываются исключительно для достижения конкретных целей.
            </li>
            <li>
              <strong>Минимизация данных</strong>: Обрабатываемые данные должны быть актуальными,
              достаточными и не избыточными по отношению к заявленным целям обработки.
            </li>
          </ol>

          <h2>4. Получение согласия на обработку данных </h2>
          <ol>
            <li>
              Согласие на обработку персональных данных предоставляется пользователем при
              регистрации в Продукте и подтверждается путем проставления соответствующей отметки на
              сайте.
            </li>
            <li>
              Пользователь вправе в любое время отозвать свое согласие на обработку персональных
              данных, направив соответствующее заявление оператору.
            </li>
          </ol>

          <h2>5. Права пользователя </h2>
          <ol>
            <li>
              Пользователь имеет право на получение информации, касающейся обработки его
              персональных данных.
            </li>
            <li>
              Пользователь имеет право требовать изменения, блокирования или удаления своих
              персональных данных в случае, если они являются неполными, устаревшими или неточными.
            </li>
            <li>
              Пользователь имеет право требовать прекращения обработки своих персональных данных и
              их удаления при отсутствии законных оснований для обработки данных.
            </li>
          </ol>
          <h2>6. Обязанности оператора </h2>
          <ol>
            <li>
              Оператор обязан обеспечивать защиту персональных данных от несанкционированного
              доступа, изменения, блокирования, копирования, распространения, предоставления,
              удаления персональных данных, а также от иных неправомерных действий.
            </li>
            <li>
              Оператор обязан разъяснять пользователю его права, связанные с обработкой персональных
              данных.
            </li>
            <li>
              Оператор обязан уведомлять пользователя о факте обработки его персональных данных и
              целях такой обработки.
            </li>
          </ol>
          <h2>7. Меры по обеспечению безопасности данных </h2>
          <ol>
            <li>
              Оператор принимает правовые, организационные и технические меры по обеспечению защиты
              персональных данных.
            </li>
            <li>
              Оператор назначает лицо, ответственное за осуществление внутреннего контроля за
              обработкой персональных данных.
            </li>

            <li>
              Оператор регулярно проводит обучение сотрудников, непосредственно осуществляющих
              обработку персональных данных.
            </li>
          </ol>
          <h2>8. Трансграничная передача данных </h2>
          <ol>
            <li>
              Трансграничная передача персональных данных осуществляется только при обеспечении
              надлежащего уровня защиты прав субъектов персональных данных на территории
              иностранного государства.
            </li>
          </ol>
          <h2>9. Изменения в политике конфиденциальности </h2>
          <ol>
            <li>
              Оператор оставляет за собой право вносить изменения в настоящую политику
              конфиденциальности. Пользователи будут уведомлены о таких изменениях посредством
              размещения новой редакции политики на сайте Продукта.
            </li>
          </ol>
          <h2>10. Контакты </h2>
          <p>
            Для реализации своих прав и получения информации пользователь может обратиться к
            оператору по следующим контактам:
          </p>
          <ul>
            <li>
              <strong>Наименование оператора</strong>: ООО Кэтлоджик
            </li>
            <li>
              <strong>Адрес</strong>: Республика Беларусь, г. Минск, 220073, ул. Харьковская, д. 15,
              пом. 113
            </li>

            <li>
              <strong>Электронная почта</strong>: info@stafflow.by
            </li>
            <li>
              <strong>Телефон</strong>: +375 29 148 1444
            </li>
          </ul>
          <p>
            Настоящая политика конфиденциальности разработана в соответствии с Законом Республики
            Беларусь «О защите персональных данных» от 7 мая 2021 г. № 99-З и вступает в силу с
            момента ее публикации на сайте Продукта.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
